/**
 * This file contains the Layout component which serves as the main layout for the application.
 * It renders the appropriate component based on the current route.
 * The Layout component also handles displaying toast notifications.
 *
 * Example usage:
 * <Layout />
 */

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router';
import { Toast } from '../../components/Notifications/Notifications';
import PrivateRoute from '../../components/privateRoute/PrivateRoute';
import useDeviceDetect from '../../hooks/UseDeviceDetect';
import { isAuthenticated, isGlobalEnv } from '../../libs/helper/HelperFunctions';
import { IApplicationState, useAppSelector } from '../../libs/store/reducers';
import { routes } from '../../navigation/Routes';
import ProtectedRoute from '../../components/protectedRoute/ProtectedRoute';
import { RBACMapping } from '../../components/Auth/AuthenticationPageConstants';
import Loader from '../../components/Loader/Loader';
import IframeTest from '../KeyAssets/iframe-test';
const AuthenticationPage = lazy(() => import('../../components/Auth/AuthenticationPage'));
const BlockedScreen = lazy(() => import('../BlockedScreen/BlockedScreen'));
const AssetAuthenticationRoutes = lazy(
  () => import('../AssetAuthenticationRoutes/AssetAuthenticationRoutes'),
);
const CBOUserManagement = lazy(() => import('../CBOUserManagement/CBOUserManagement'));
const CBOUserClientMapping = lazy(() => import('../CBOUserClientMapping/CBOUserClientMapping'));
const CBODashboardPursuitLead = lazy(
  () => import('../CBODashboardPursuitLead/CBODashboardPursuitLead'),
);
const CBOJourneyBuilder = lazy(() => import('../CBOJourneyBuilder/CBOJourneyBuilder'));
const CapabilityManagement = lazy(() => import('../CBOCapabilityManagement/CapabilityManagement'));
const UnauthorizedModal = lazy(
  () => import('../../components/Auth/UnauthorizedModal/UnauthorizedModal'),
);
const CBOWebAnalytics = lazy(() => import('../CBOWebAnanlytics/CBOWebAnalytics'));
const CBOEngagementSummary = lazy(() => import('../CBOEngagementSummary/CBOEngagementSummary'));
const ClientEngagementManagement = lazy(
  () => import('../CBOClientEngagementManagement/ClientEngagementManagement'),
);
const EngMarketplaceAssets = lazy(() => import('../CBOMarketplace/EngMarketplaceAssets'));
const CBOLogin = lazy(() => import('../CBOLogin/CBOLogin'));
const CBOMarketplaceSearch = lazy(() => import('../CBOMarketplaceSearch/CBOMarketplaceSearch'));
const CBOTruNorthDashboard = lazy(() => import('../KeyAssets/TruNorthDashboard/TruNorthDashboard'));
const CBOSupport = lazy(() => import('../CBOSupport/CBOSupport'));
const CBOEngagementDashboard = lazy(
  () => import('../CBOEngagementDashboard/CBOEngagementDashboard'),
);
const CBOSmartStart = lazy(() => import('../CBOSmartStart/CBOSmartStart'));

const Layout = () => {
  const { showToast, description, message, type, duration, placement, key } = useAppSelector(
    ({
      toast: { showToast, description, message, type, duration, placement, key },
    }: IApplicationState) => ({
      showToast,
      description,
      message,
      type,
      duration,
      placement,
      key,
    }),
  );

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setShowNotification(showToast);
  }, [showToast]);

  return (
    <>
      {Toast(description, message, type, duration, placement, key, showToast)}
      <Suspense
        fallback={
          <Loader loading loaderName={''}>
            <></>
          </Loader>
        }
      >
        <Routes>
          <Route path={routes.authentication} element={<AuthenticationPage />} />
          <Route path={routes.login.error} element={<BlockedScreen />} />
          <Route
            path={`${routes.authentication}/:dyanmicAssetParam1`}
            element={
              <PrivateRoute hideHomePage>
                <AssetAuthenticationRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path={`${routes.authentication}/:dyanmicAssetParam1/:dyanmicAssetParam2`}
            element={
              <PrivateRoute hideHomePage>
                <AssetAuthenticationRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboUserManagement}
            element={
              <PrivateRoute>
                <CBOUserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboUserClientMapping}
            element={
              <PrivateRoute>
                <CBOUserClientMapping />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboDashboard}
            element={
              <PrivateRoute>
                <CBODashboardPursuitLead />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboEngagementDashboard}
            element={
              <PrivateRoute>
                <CBOEngagementDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboJourneyBuilder}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.journeyList}>
                  <CBOJourneyBuilder />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboCapabilityManagement}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.capabilityList}>
                  <CapabilityManagement />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.accessDenied}
            element={
              <PrivateRoute>
                <UnauthorizedModal />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboReports}
            element={
              <PrivateRoute>
                <CBOWebAnalytics />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboEngagementSummary}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.engagementSummary}>
                  <CBOEngagementSummary />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.clientEngagementManagement}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.clientEngagementView}>
                  <ClientEngagementManagement />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboMarketplace}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.engagementMarketplace}>
                  <EngMarketplaceAssets />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboMarketplaceSearch}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.marketplaceSearch}>
                  <CBOMarketplaceSearch />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboTruNorthDashboard}
            element={
              <PrivateRoute>
                <CBOTruNorthDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={routes.cboFeedback}
            element={
              <PrivateRoute>
                <ProtectedRoute action={RBACMapping.feedback}>
                  <CBOSupport />
                </ProtectedRoute>
              </PrivateRoute>
            }
          />
          {!isGlobalEnv() && (
            <Route
              path={routes.cboSmartStart}
              element={
                <PrivateRoute>
                  <ProtectedRoute action={RBACMapping.smartStart}>
                    <CBOSmartStart />
                  </ProtectedRoute>
                </PrivateRoute>
              }
            />
          )}
          {/* <Route
            path={routes.cboIframeTest}
            element={
              <PrivateRoute>
                <IframeTest />
              </PrivateRoute>
            }
          /> */}
          <Route path={routes.cbologin} element={<CBOLogin />} />
          <Route
            path="*"
            element={<Navigate to={isAuthenticated() ? routes.cboDashboard : routes.cbologin} />}
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default Layout;
