import { Environment } from '../AppConstants';
import { ICognitoProps } from '../libs/models/LoginModels';
import { ONECBO, CWB, ASSESS, DCA, TURBOCODE, DEP } from './AWSConstants';

export const congnitoConfiguration = () => {
  let COGNITO: ICognitoProps;
  COGNITO = {
    LOGIN_TYPE: ONECBO.LOGIN_TYPE,
    ENV: ONECBO.ENV,
    REGION: ONECBO.REGION,
    USER_POOL_ID: ONECBO.USER_POOL_ID,
    APP_CLIENT_ID: ONECBO.APP_CLIENT_ID,
    DOMAIN: ONECBO.DOMAIN,
    SCOPE: ONECBO.SCOPE,
    REDIRECT_SIGN_IN: ONECBO.REDIRECT_SIGN_IN,
    REDIRECT_SIGNOUT: ONECBO.REDIRECT_SIGNOUT,
    RESPONSE_TYPE: ONECBO.RESPONSE_TYPE,
    CWB: {
      APP_CLIENT_ID: CWB.APP_CLIENT_ID,
      DOMAIN: CWB.DOMAIN,
      REDIRECT_SIGN_IN: CWB.REDIRECT_SIGN_IN,
      RESPONSE_TYPE: CWB.RESPONSE_TYPE,
    },
    ASSESS: {
      APP_CLIENT_ID: ASSESS.APP_CLIENT_ID,
      APP_REALM: ASSESS.APP_REALM,
      DOMAIN: ASSESS.DOMAIN,
      REDIRECT_SIGN_IN: ASSESS.REDIRECT_SIGN_IN,
      RESPONSE_TYPE: ASSESS.RESPONSE_TYPE,
    },
    DCA: {
      APP_CLIENT_ID: DCA.APP_CLIENT_ID,
      DOMAIN: DCA.DOMAIN,
      REDIRECT_SIGN_IN: DCA.REDIRECT_SIGN_IN,
      RESPONSE_TYPE: DCA.RESPONSE_TYPE,
    },
    TURBOCODE: {
      APP_CLIENT_ID: TURBOCODE.APP_CLIENT_ID,
      DOMAIN: TURBOCODE.DOMAIN,
      REDIRECT_SIGN_IN: TURBOCODE.REDIRECT_SIGN_IN,
      RESPONSE_TYPE: TURBOCODE.RESPONSE_TYPE,
      AUTHORITY: TURBOCODE.AUTHORITY,
      CODE_CHALLENGE: TURBOCODE.CODE_CHALLENGE,
    },
    DEP: {
      APP_CLIENT_ID: DEP.APP_CLIENT_ID,
      DOMAIN: DEP.DOMAIN,
      REDIRECT_SIGN_IN: DEP.REDIRECT_SIGN_IN,
      RESPONSE_TYPE: DEP.RESPONSE_TYPE,
      AUTHORITY: DEP.AUTHORITY,
      SCOPE: DEP.SCOPE,
      CODE_CHALLENGE: DEP.CODE_CHALLENGE,
    },
  };

  return COGNITO;
};

export const COGNITO_EXTERNAL = {
  REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_918vSUxXm',
  APP_CLIENT_ID: '4cvp40l7pgjj8821k3j769osa4',
};
