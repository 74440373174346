import { Reducer } from 'redux';
import { NotificationTypes } from '../../../components/Notifications/NotificationsConstants';
import { ToastAction, ToastActionTypes } from '../actions/ToastActions';
import ToastState from '../state/ToastState';
import { NotificationPlacement } from 'antd/es/notification/interface';

export const initialState: ToastState = {
  error: '',
  loading: false,
  showToast: false,
  description: '',
  message: '',
  type: NotificationTypes.INFO,
  duration: 4.5,
  placement: 'topRight',
  key: '',
};

export const toastReducer: Reducer<ToastState> = (state = initialState, action: ToastAction) => {
  switch (action.type) {
    case ToastActionTypes.SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        type: action.data?.type as NotificationTypes,
        description: action.data?.description as string,
        message: action.data?.message as string,
        duration: action.data?.duration as number,
        placement: action.data?.placement as NotificationPlacement,
        key: action.data?.key as string,
      };

    case ToastActionTypes.HIDE_TOAST:
      return {
        ...state,
        showToast: false,
      };

    default:
      return state;
  }
};
