export enum NavMenuOptions {
  OVERVIEW = 'Tangra... enabling the cloud journey',
  OPERATIONS = 'Operations - Jobs Ruleset',
  ASSET_AUTHORISATION = 'Asset Authorization',
  WEB_ANALYTICS = 'Web Analytics',
  QUICK_LINKS = 'Quick Links',
  KEY_CONTACTS = 'Key Contacts',
  FEEDBACK = 'Feedback',
  FEEDBACK_RESPONSE = 'Admin - Feedback',
  USER_MANAGEMENT = 'User Management',
  USER_CLIENT_MAPPING = 'User Client Mapping',
  ACCOUNT_MANAGEMENT = 'Account & Project Management',
  ASSESSMENT = 'Assessment',
  CBOSUMMARY = 'CBO Summary',
  ENG_DASHBOARD = 'My Dashboard',
  CBO_ENGAGEMENT_DASHBOARD = 'Engagement Dashboard',
  CBO_PURSUIT_SUMMARY = 'Pursuit Summary',
  CBO_ENGAGEMENT_SUMMARY = 'Engagement Summary',
  CBO_MARKETPLACE = 'Marketplace',
  CBO_ASSET_AUTHORIZATION = 'Asset Authorization',
  CBO_REPORTS = 'Reports',
  CBO_CLIENT_MANAGEMENT = 'Client Management',
  CBO_USER_MANAGEMENT = 'User Management',
  CBO_ACCESS_DENIED = 'CboAccessDenied',
  CBO_CAPABILITY_MANAGEMENT = 'Master Data',
  CBO_JOURNEY_BUILDER = 'Journey Builder',
  CBO_SMART_START = 'SmartStart Management',
  CBO_MARKETPLACE_SEARCH = 'Marketplace Search',
  CBO_USER_FEEDBACK = 'Application Support',
}

export const {
  APP_SETTINGS: { ONECBO, CWB, DCA },
} = window;
export const pageSizeOptions = ['5', '10', '15', '20'];

export const CBOdevEnvironments = [
  'cbo.d1r655awolnri1.amplifyapp.com',
  'dev.corebusinessone.dca.deloitte.com',
  'qa.dlkkiwthevzqo.amplifyapp.com',
];
export const localhostEnvironments = ['localhost'];
export const CBOprodEnvironments = [
  'cbo-prod.d1r655awolnri1.amplifyapp.com',
  'corebusinessone.dca.deloitte.com',
  'prod.dv6ptkfs3966.amplifyapp.com',
];
export const termsandConditionsUrl =
  'https://www2.deloitte.com/us/en/footerlinks1/deloitte-application-privacy-notice.html';
export enum Environment {
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
  LOCALHOST = 'localhost',
  QA_GLOBAL = 'qa-global',
  PROD_GLOBAL = 'prod-global',
}
/**
 * Login types used in CBOLogin.
 */
export const LoginType = {
  CUSTOM: 'custom',
  SSO: 'sso',
  BOTH: 'both',
};

export enum PipelineType {
  ADD = 'add',
  EDIT = 'edit',
}

export enum CloudServiceProvider {
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
  ORACLE = 'oracle',
  OPENSHIFT = 'openshift',
  MULTICLOUD = 'multicloud',
}

export const DUMMY_ACCESS_TOKEN = '1';

export const mobileScreenConstant = 720;

export enum StatusType {
  active = 'Active',
  inactive = 'Inactive',
  upcoming = 'Upcoming',
}
export const cmsPortalUrls = 'https://deloitteauthqa.opencloudportal.com/';
export const cb360PortalUrl = 'https://frontend-qa.cb360foundation.ml/';

export const logoutModalText =
  'Due to user inactivity your session is about to expire. You want to stay logged in?';
export const timeOutTime = 1000 * 60 * 25; // 25 mins
