/* eslint-disable max-lines */
import { Reducer } from 'redux';
import { EngagementPhases } from '../../../../components/CBO/CBOConstants';
import {
  IJourneyCapabilitiesData,
  IEngagementConfigurationResponse,
  IEngagementConfigurationSaveResponse,
  IPredefinedJourneyResponse,
  IADAccessTokenResponse,
  IKeyAssetsIconResponseListObject,
  IProduct,
  IValidatePursuitResponse,
  IJourneyCapabilitiesResponse,
  IStatusUpdateResponse,
  ITagsResponse,
  ICountryMappingresponse,
} from '../../../models/cbo/EngagementModels';
import { EngagementAction, EngagementActionTypes } from '../../actions/cbo/EngagementAction';
import EngagementState from '../../state/cbo/EngagementState';

const defaultJourneyCapabilitiesData: IJourneyCapabilitiesData[] = [
  {
    capabilityId: '',
    phase: EngagementPhases.ASSESS,
    capabilityName: '',
    capabilityInfo: '',
    enabled: false,
    products: [],
    relevance: '',
    sequenceNumber: 0,
    features: [],
  },
];

const defaultengagementConfigData: IEngagementConfigurationResponse = {
  clientIcon: '',
  engagementId: '',
  engagementName: '',
  clientName: '',
  clientId: '',
  industry: '',
  sector: '',
  philosophyPhaseMappingData: [],
  updatedDate: '',
  journeyCapability: defaultJourneyCapabilitiesData,
  offering: '',
  engagementUsers: [],
  journeyList: [],
  planTabEnabled: true,
  jupiterId: '',
  tags: [],
};
const defaultDomainList: string[] = [];
const deafultEngagementConfigSaveResponse: IEngagementConfigurationSaveResponse = {
  engagementId: '',
  engagementName: '',
};

const defaultPredefinedJourneyList: IPredefinedJourneyResponse[] = [
  {
    journeyId: '',
    journeyName: '',
  },
];

const defaultADAccessToken: IADAccessTokenResponse = {
  access_token: '',
};

export const initialState: EngagementState = {
  predefinedJourneyList: defaultPredefinedJourneyList,
  journeyCapabilitiesResponse: {} as IJourneyCapabilitiesResponse,
  engagementConfiguration: defaultengagementConfigData,
  pursuitConfiguration: defaultengagementConfigData,
  configResponseCompleted: true,
  engagementConfigSaveResponse: deafultEngagementConfigSaveResponse,
  adAccessToken: defaultADAccessToken,
  KeyAssetList: [],
  KeyAssetListCallCompleted: false,
  KeyAssetListCallLoading: false,
  loading: false,
  error: '',
  isKeyAssetsIconListLoading: false,
  keyAssetsIconList: [] as IKeyAssetsIconResponseListObject[],
  domainList: defaultDomainList,
  validatePursuitData: {} as IValidatePursuitResponse,
  pursuitStatusUpdateResponse: {} as IStatusUpdateResponse,
  engagementStatusUpdateResponse: {} as IStatusUpdateResponse,
  tagsList: [],
  tagsListLoading: false,
  countryMappingList: [],
};

export const EngagementReducer: Reducer<EngagementState> = (
  state = initialState,
  action: EngagementAction,
) => {
  switch (action.type) {
    case EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        predefinedJourneyList: defaultPredefinedJourneyList,
      };
    case EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        predefinedJourneyList: action.data as IPredefinedJourneyResponse[],
      };
    case EngagementActionTypes.GET_CE_PREDEFINED_JOURNEY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        journeyCapabilitiesResponse: {} as IJourneyCapabilitiesResponse,
      };
    case EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        journeyCapabilitiesResponse: action.data as IJourneyCapabilitiesResponse,
      };
    case EngagementActionTypes.GET_JOURNEYCAPABILITY_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_STARTED:
      return {
        ...state,
        configResponseCompleted: false,
        error: undefined,
        engagementConfiguration: defaultengagementConfigData,
      };
    case EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_SUCCESS:
      if (action?.flag?.isEngagement) {
        return {
          ...state,
          configResponseCompleted: true,
          error: undefined,
          engagementConfiguration: action.data as IEngagementConfigurationResponse,
        };
      } else {
        return {
          ...state,
          configResponseCompleted: true,
          error: undefined,
          pursuitConfiguration: action.data as IEngagementConfigurationResponse,
        };
      }
    case EngagementActionTypes.GET_ENGAGEMENTCONFIG_DATA_ERROR:
      return {
        ...state,
        configResponseCompleted: true,
        error: action.data,
      };

    case EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        engagementConfigSaveResponse: deafultEngagementConfigSaveResponse,
      };
    case EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        engagementConfigSaveResponse: action.data as IEngagementConfigurationSaveResponse,
      };
    case EngagementActionTypes.SAVE_ENGAGEMENTCONFIG_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case EngagementActionTypes.RESET_SELECTED_ENGAGEMENT:
      return {
        ...state,
        loading: true,
        error: undefined,
        engagementConfigSaveResponse: deafultEngagementConfigSaveResponse,
      };
    case EngagementActionTypes.GET_AD_ACCESS_TOKEN_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        adAccessToken: defaultADAccessToken,
      };
    case EngagementActionTypes.GET_AD_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        adAccessToken: action.data as IADAccessTokenResponse,
      };
    case EngagementActionTypes.GET_AD_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_STARTED:
      return {
        ...state,
        isKeyAssetsIconListLoading: true,
        error: undefined,
        keyAssetsIconList: [] as IKeyAssetsIconResponseListObject[],
      };
    case EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_SUCCESS:
      return {
        ...state,
        isKeyAssetsIconListLoading: false,
        error: undefined,
        keyAssetsIconList: action.data as IKeyAssetsIconResponseListObject[],
      };
    case EngagementActionTypes.GET_KEY_ASSETS_ICONS_LIST_ERROR:
      return {
        ...state,
        isKeyAssetsIconListLoading: false,
        error: action.data as Error,
      };
    case EngagementActionTypes.GET_KEY_ASSETS_LIST_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        KeyAssetListCallCompleted: false,
        KeyAssetListCallLoading: true,
        KeyAssetList: [],
      };
    case EngagementActionTypes.GET_KEY_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        KeyAssetListCallCompleted: true,
        KeyAssetListCallLoading: false,
        KeyAssetList: action.data as IProduct[],
      };
    case EngagementActionTypes.GET_KEY_ASSETS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        KeyAssetListCallCompleted: true,
        KeyAssetListCallLoading: false,
        error: action.data,
      };

    //conversion
    case EngagementActionTypes.GET_CONVERSION_DATA_STARTED:
      return {
        ...state,
        error: undefined,
        pursuitStatusUpdateResponse: {} as IStatusUpdateResponse,
      };
    case EngagementActionTypes.GET_CONVERSION_DATA_SUCCESS:
      return {
        ...state,
        error: undefined,
        pursuitStatusUpdateResponse: action.data as IStatusUpdateResponse,
      };
    case EngagementActionTypes.GET_CONVERSION_DATA_ERROR:
      return {
        ...state,
        error: action.data as Error,
      };
    case EngagementActionTypes.RESST_SUMMARY_DATA:
      return {
        ...state,
        engagementConfiguration: defaultengagementConfigData,
        pursuitConfiguration: defaultengagementConfigData,
        KeyAssetList: [],
      };
    // domain list
    case EngagementActionTypes.GET_DOMAIN_DATA_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        domainList: defaultDomainList,
      };
    case EngagementActionTypes.GET_DOMAIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        domainList: action.data as string[],
      };
    case EngagementActionTypes.GET_DOMAIN_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    //Start: CBO-3027_AI_AUTO_ENHANCE_CODE
    case EngagementActionTypes.VALIDATE_PURSUIT_NAME_STARTED:
      return {
        ...state,
        validatePursuitData: {} as IValidatePursuitResponse,
        error: undefined,
      };
    case EngagementActionTypes.VALIDATE_PURSUIT_NAME_SUCCESS:
      return {
        ...state,
        validatePursuitData: action.data as IValidatePursuitResponse,
        error: undefined,
      };
    case EngagementActionTypes.VALIDATE_PURSUIT_NAME_ERROR:
      return {
        ...state,
        error: action.data,
      };
    //End: CBO-3027_AI_AUTO_ENHANCE_CODE

    case EngagementActionTypes.POST_ENG_CLOSURE_DATE_STARTED:
      return {
        ...state,
        loading: true,
        engagementStatusUpdateResponse: {} as IStatusUpdateResponse,
      };
    case EngagementActionTypes.POST_ENG_CLOSURE_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        engagementStatusUpdateResponse: action.data as IStatusUpdateResponse,
      };
    case EngagementActionTypes.POST_ENG_CLOSURE_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EngagementActionTypes.RESET_PURSUIT_STATUS_RESPONSE:
      return {
        ...state,
        pursuitStatusUpdateResponse: {} as IStatusUpdateResponse,
      };
    case EngagementActionTypes.RESET_ENGAGEMENT_STATUS_RESPONSE:
      return {
        ...state,
        isDeleteEngSuccessful: false,
        engagementStatusUpdateResponse: {} as IStatusUpdateResponse,
      };
    case EngagementActionTypes.GET_TAGS_LIST_STARTED:
      return {
        ...state,
        tagsList: [],
        tagsListLoading: true,
      };
    case EngagementActionTypes.GET_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tagsList: action.data as ITagsResponse[],
        tagsListLoading: false,
      };
    case EngagementActionTypes.GET_TAGS_LIST_ERROR:
      return {
        ...state,
        tagsList: [],
        error: action.data,
        tagsListLoading: false,
      };

    case EngagementActionTypes.RESET_TAGS_LIST:
      return {
        ...state,
        tagsList: [],
        tagsListLoading: false,
      };

    case EngagementActionTypes.GET_COUNTRY_MAPPING_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case EngagementActionTypes.GET_COUNTRY_MAPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        countryMappingList: action.data as ICountryMappingresponse[],
      };
    case EngagementActionTypes.GET_COUNTRY_MAPPING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    default:
      return state;
  }
};
