import * as redux from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import reducers, { IApplicationState } from './reducers';
import sagas from './sagas';
import { applyMiddleware } from 'redux';

export type AppStore = redux.Store<IApplicationState>;
// eslint-disable-next-line import/no-mutable-exports
export let store: AppStore;

//Configuration for the Persisted Store.
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['login'],
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const sagaMiddleware = createSagaMiddleware();

//It provides a way of combining the reducers with the configuration, and return an enhanced Reducer.
const persistedReducer = persistReducer(persistConfig, reducers);

// Creates store and sets it into singleton (Store.ts/store)
const createAppStore = (testing?: boolean | undefined | null) => {
  store = redux.createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(sagas);
  return store;
};

// initialize and export
//persistStore : Ensures your redux state is stored to persisted storage whenever it changes.
export const persistor = persistStore(createAppStore());
