import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import { persistor, store } from './libs/store';
import * as serviceWorker from './serviceWorkerRegistration.js';

const config = {
  theme: {
    token: {
      fontFamily:
        'Poppins, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      fontSize: 14,
    },
  },
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConfigProvider theme={config.theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

// serviceWorker.register();
